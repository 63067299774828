import { ArrowLeftOutlined, ArrowRightOutlined } from "@ant-design/icons";
import { Button } from "antd";
import { ReactNode } from "react";
import { FormattedMessage } from "react-intl";
import { SubmitButton } from "../form";
import { Space } from "../shared";

export function WizardButtons({
  nextStep,
  nextText,
  onBack,
}: {
  nextStep?: boolean;
  nextText?: ReactNode;
  onBack?: () => void;
}) {
  return (
    <Space align="end" justify="flex-end">
      {onBack && (
        <Button icon={<ArrowLeftOutlined />} onClick={onBack}>
          <span>
            <FormattedMessage id="back" defaultMessage="Back" />
          </span>
        </Button>
      )}

      {nextStep && (
        <SubmitButton
          icon={<ArrowRightOutlined />}
          data-testid="onboarding/next-button"
        >
          <span>{nextText || <FormattedMessage id="next" />}</span>
        </SubmitButton>
      )}
    </Space>
  );
}
