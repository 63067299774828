import { Badge, Card, Col, Row, Typography } from "antd";
import Link from "next/link";
import { defineMessages, useIntl } from "react-intl";
import { formatNumber, formatStatus, statusColor } from "../../lib/formats";
import {
  useCurrentUser,
  useTranslate,
  useWorkOrderTypeFormat,
  WO_CATEGORY_COLORS_MAP,
} from "../../lib/hooks";
import routes from "../../lib/routes";
import DashboardCard from "./DasboardCard";
import {
  DashboardWorkOrderStatusSummary,
  DashboardWorkOrderTypeSummary,
  WorkOrderStatus,
} from "../../lib/graphql";
import { RecentWorkOrdersTable } from "./RecentWorkOrdersTable";
import { useCallback } from "react";
import { Column } from "@ant-design/plots";
import { Space } from "../shared";

const messages = defineMessages({
  workOrdersByType: {
    id: "dashboard.workOrdersByType",
    defaultMessage: "workOrdersByType",
  },
  recentWorkOrders: {
    id: "dashboard.recentWorkOrders",
    defaultMessage: "recentWorkOrders",
  },
  completedWorkOrders: {
    id: "dashboard.completedWorkOrders",
    defaultMessage: "completedWorkOrders",
  },
});

const StatusCard = ({
  status,
  route,
  loading,
  count,
  label,
}: {
  status: string;
  route?: string;
  loading: boolean;
  count: number;
  label?: string;
}) => (
  <Col flex="1 1 200px">
    <Link
      href={route || routes.agro.workOrders.indexWithFilters({ status })}
      as={route || routes.agro.workOrders.indexWithFilters({ status })}
      prefetch={false}
      passHref
    >
      <DashboardCard
        hoverable
        loading={loading}
        value={formatNumber(count)}
        title={
          <>
            <Badge color={statusColor(status)} />{" "}
            {label || formatStatus(status, "text")}
          </>
        }
      />
    </Link>
  </Col>
);

export function WorkOrders({
  loading,
  workOrderStatusSummary,
  totalAwaitingInventoryWorkOrders,
  workOrderTypeSummary,
}: {
  loading: boolean;
  workOrderStatusSummary?: DashboardWorkOrderStatusSummary[] | null;
  totalAwaitingInventoryWorkOrders?: number | null;
  workOrderTypeSummary?: DashboardWorkOrderTypeSummary[] | null;
}) {
  const intl = useIntl();
  const translate = useTranslate();
  const { formatWorkOrderType, formatWorkOrderCategory } =
    useWorkOrderTypeFormat();
  const { currentTenant } = useCurrentUser();

  const chartData = workOrderTypeSummary?.map(
    ({ workOrderType, workOrderCategory, count }) => ({
      label: formatWorkOrderType(workOrderType),
      workOrderCategory,
      workOrderCategoryLabel: formatWorkOrderCategory(workOrderCategory),
      count,
    })
  );

  const colorsCallback = useCallback(
    (v: any) => {
      const category =
        chartData?.find(({ label }) => label === v.label)?.workOrderCategory ||
        "agricultular";

      return WO_CATEGORY_COLORS_MAP[category];
    },
    [chartData]
  );

  const renderStatusCard = useCallback(
    (status: WorkOrderStatus) => (
      <StatusCard
        key={`work-order-status-${status}`}
        loading={loading}
        status={status}
        count={
          workOrderStatusSummary?.find((s) => s.status === status)?.count || 0
        }
      />
    ),
    [loading, workOrderStatusSummary]
  );

  return (
    <Space vertical>
      <Row gutter={[8, 8]}>
        {[WorkOrderStatus.Open, WorkOrderStatus.InProgress].map(
          renderStatusCard
        )}

        {currentTenant.inventoryRequestEnabled && (
          <StatusCard
            loading={loading}
            status={"awaitingInventory"}
            route={routes.agro.workOrders.indexWithFilters({
              inventoryStatus: "requestingReturning",
            })}
            count={totalAwaitingInventoryWorkOrders || 0}
            label={translate("inventoryStatuses.awaitingInventory")}
          />
        )}

        {[
          WorkOrderStatus.Completed,
          WorkOrderStatus.Canceled,
          WorkOrderStatus.Reverted,
        ].map(renderStatusCard)}
      </Row>

      <Row gutter={[8, 8]}>
        <Col flex="1">
          <Card loading={loading} style={{ minHeight: 350, height: "100%" }}>
            <Space vertical size="large">
              <Typography.Title level={4}>
                {intl.formatMessage(messages.workOrdersByType)}
              </Typography.Title>

              <Column
                data={chartData || []}
                xField="workOrderCategoryLabel"
                yField="count"
                seriesField="label"
                isGroup
                columnStyle={{
                  radius: [20, 20, 0, 0],
                }}
                xAxis={{ title: null }}
                yAxis={{
                  title: {
                    text: intl.formatMessage(messages.completedWorkOrders),
                  },
                }}
                color={colorsCallback}
                legend={false}
              />
            </Space>
          </Card>
        </Col>

        <Col flex="1">
          <Card loading={loading} style={{ height: "100%" }}>
            <Space vertical size="large">
              <Typography.Title level={4}>
                <Link
                  href={routes.agro.workOrders.index}
                  as={routes.agro.workOrders.index}
                  prefetch={false}
                  className="text-link"
                >
                  {intl.formatMessage(messages.recentWorkOrders)}
                </Link>
              </Typography.Title>

              <RecentWorkOrdersTable />
            </Space>
          </Card>
        </Col>
      </Row>
    </Space>
  );
}
