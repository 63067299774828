import { Avatar, Col, Row, Typography } from "antd";
import { defineMessages, FormattedMessage, useIntl } from "react-intl";
import BasicLayout from "../layouts/BasicLayout";
import {
  formatAssetPath,
  formatDate,
  formatTenantName,
  formatTestDataResetAt,
  useCurrencySwitch,
} from "../lib/formats";
import { useCurrentUser } from "../lib/hooks";
import routes from "../lib/routes";
import Dashboard from "../components/dashboard/Dashboard";
import Onboarding from "../components/onboarding";
import SiteProductTour from "../components/onboarding/ProductTour";

const messages = defineMessages({
  pageTitle: { id: "dashboard.pageTitle", defaultMessage: "Dashboard" },
});

function Home() {
  const intl = useIntl();
  const { currentTenant, forceOnboarding } = useCurrentUser();

  const { currentCurrency, currencySwitch } = useCurrencySwitch();

  const tenantName = formatTenantName(currentTenant);

  return (
    <BasicLayout
      title={intl.formatMessage(messages.pageTitle)}
      breadcrumbs={
        forceOnboarding
          ? undefined
          : [
              {
                path: routes.dashboard,
                breadcrumbName: intl.formatMessage(messages.pageTitle),
              },
            ]
      }
      headerContent={
        !forceOnboarding && (
          <Row justify="space-between" align="bottom">
            <Col>
              <Avatar
                src={currentTenant.logo && formatAssetPath(currentTenant.logo)}
                size={60}
                alt={`${tenantName} logo`}
                style={{ marginRight: 16 }}
              >
                {tenantName?.[0]}
              </Avatar>
              <Typography.Text type="secondary">
                {currentTenant.liveTenantId ? (
                  formatTestDataResetAt(currentTenant)
                ) : (
                  <FormattedMessage
                    id="dashboard.subtitle"
                    defaultMessage="dashboard.subtitle"
                    values={{
                      createdAt: formatDate(currentTenant.createdAt, {
                        day: "2-digit",
                        month: "long",
                        year: "numeric",
                      }),
                    }}
                  />
                )}
              </Typography.Text>
            </Col>
            <Col>{currencySwitch}</Col>
          </Row>
        )
      }
      contentStyle={{ background: "transparent", padding: "0px" }}
    >
      {forceOnboarding ? (
        <Onboarding />
      ) : (
        <>
          <SiteProductTour />
          <Dashboard currentCurrency={currentCurrency} />
        </>
      )}
    </BasicLayout>
  );
}

export default Home;
