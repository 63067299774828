import { Card, Spin } from "antd";
import { useMemo } from "react";
import { FormattedMessage } from "react-intl";
import { useCurrentLocale, useCurrentUser, useLocality } from "../../lib/hooks";
import { findGeoCenter } from "../../lib/mapbox/utils";
import dynamic from "next/dynamic";
const ReactWeather = dynamic(() => import("../weather/Widget"), {
  loading: () => <Spin />,
});

export function Weather() {
  const { currentLocalityId } = useCurrentUser();
  const { locality } = useLocality(currentLocalityId);
  const { currentLocale } = useCurrentLocale();

  const localityCoorinates = useMemo(() => {
    if (!locality) return;

    if (locality.geoJson) {
      const center = findGeoCenter(locality.geoJson);
      if (center)
        return {
          lon: center.geometry.coordinates[0],
          lat: center.geometry.coordinates[1],
        };
    } else if (locality.latitude && locality.longitude) {
      return {
        lon: locality.longitude,
        lat: locality.latitude,
      };
    }
  }, [locality]);

  if (!locality || !localityCoorinates)
    return (
      <Card style={{ height: "100%" }}>
        <FormattedMessage id="noData" />
      </Card>
    );

  return <ReactWeather {...localityCoorinates} lang={currentLocale} />;
}
