import { Divider, Typography } from "antd";
import { FormattedMessage } from "react-intl";
import { NewLocalityForm } from "../../pages/localities/new";
import { WizardButtons } from "./WizardButtons";

export function Localities({ onNextClick }: { onNextClick: () => void }) {
  return (
    <>
      <Typography.Title level={3}>
        <FormattedMessage
          id="onboarding.locality.title"
          defaultMessage="Farm Information"
        />
      </Typography.Title>

      <Typography.Text type="secondary">
        <FormattedMessage
          id="onboarding.locality.hint"
          defaultMessage="informationHint"
        />
      </Typography.Text>

      <Divider />

      <NewLocalityForm afterSubmit={onNextClick}>
        <Divider />

        <WizardButtons nextStep />
      </NewLocalityForm>
    </>
  );
}
