import { Pie } from "@ant-design/plots";
import { sumBy } from "lodash";
import { useEffect, useMemo, useState } from "react";
import { useIntl } from "react-intl";
import { Interactions, roundUnit } from "../../lib/formats";
import { ExpenseRecordFragment } from "../../lib/graphql";
import { useMediaXs, usePermissions } from "../../lib/hooks";
import { buildTree } from "../../lib/hooks/reports/treeBuilder";
import { useChartValueFormatter, usePieTotalFormater } from "./ActivitiesPie";

export default function ExpenseCategoriesPie({
  expenses,
  currencyCode,
}: {
  expenses?: Pick<
    ExpenseRecordFragment,
    "id" | "parentId" | "total" | "subject" | "rate"
  >[];
  currencyCode: string;
}) {
  const intl = useIntl();
  const showCost = usePermissions((p) => !!p.settings?.showCost);

  const expensesTree = useMemo(() => buildTree(expenses || []), [expenses]);

  const isXs = useMediaXs();

  const [data, setData] = useState(expensesTree);

  useEffect(() => setData(expensesTree), [expensesTree]);

  const totalTitle = intl.formatMessage({ id: "total" });

  const [statisticTitle, setStatisticTitle] = useState(totalTitle);
  const total = sumBy(expensesTree, (e) => e.total);

  const chartValueFormatter = useChartValueFormatter(currencyCode);
  const pieTotalFormater = usePieTotalFormater({
    chartValueFormatter,
    total,
    showPercentage: showCost ? statisticTitle != totalTitle : true,
  });

  return (
    <Pie
      angleField="total"
      colorField="subject"
      data={data}
      radius={0.95}
      innerRadius={0.75}
      pieStyle={{ lineWidth: 3 }}
      meta={{
        total: {
          formatter: chartValueFormatter,
        },
      }}
      label={{
        type: "outer",
        content: "{percentage}",
      }}
      statistic={{
        title: {
          content: statisticTitle,
        },
        content: {
          customHtml: pieTotalFormater,
        },
      }}
      interactions={[Interactions.SingleSelected()]}
      legend={{
        layout: "vertical",
        position: isXs ? "bottom" : "right-top",
        itemName: {
          formatter(text, _item, index) {
            return `${text} (${roundUnit(data[index].rate * 100)}%)`;
          },
        },
      }}
      onEvent={(_, e) => {
        if (e.type == "element:click") {
          const childrens = e.data?.data?.children;
          if (childrens) {
            setData(childrens);
            setStatisticTitle(e.data?.data?.subject);
          }
        } else if (e.type == "plot:click" && !e.data) {
          setData(expensesTree);
          setStatisticTitle(totalTitle);
        }
      }}
    />
  );
}
