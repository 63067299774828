import { Col, Row } from "antd";
import { defineMessages, FormattedMessage, useIntl } from "react-intl";
import {
  CROP_STAGE_COLOR_MAP,
  useCurrentUser,
  useMediaXs,
} from "../../lib/hooks";
import {
  formatNumber,
  formatUnitValue,
  Interactions,
  roundUnit,
} from "../../lib/formats";
import DashboardCard from "./DasboardCard";
import { groupBy, sumBy } from "lodash";
import { interpolateRgb } from "d3-interpolate";
import {
  CropStageType,
  DashboardReportFragment,
  LocalityShortFragment,
} from "../../lib/graphql";
import { Sunburst, Pie } from "@ant-design/plots";
import { useState } from "react";
import DashboardCol from "./DashboardCol";
import routes from "../../lib/routes";
import { usePieTotalFormater } from "./ActivitiesPie";
import { Space } from "../shared";

const messages = defineMessages({
  cropFieldCount: {
    id: "dashboard.cropFieldCount",
    defaultMessage: "cropFieldCount",
  },
  plantCount: {
    id: "dashboard.plantCount",
    defaultMessage: "plantCount",
  },
  workerCount: {
    id: "dashboard.workerCount",
    defaultMessage: "workerCount",
  },
  machineCount: {
    id: "dashboard.machineCount",
    defaultMessage: "machineCount",
  },
  infrastructureCount: {
    id: "dashboard.infrastructureCount",
    defaultMessage: "infrastructureCount",
  },
});

type MessageType = keyof typeof messages;

const devInterpolator = interpolateRgb(
  CROP_STAGE_COLOR_MAP[CropStageType.Development],
  "#29645a"
);
const prodInterpolator = interpolateRgb(
  "#536ab8",
  CROP_STAGE_COLOR_MAP[CropStageType.Production]
);

function CropStagesChart({
  cropStageSummary,
  areaUnitAbbr,
}: Pick<DashboardReportFragment, "cropStageSummary"> & {
  areaUnitAbbr: string;
}) {
  const intl = useIntl();
  const isXs = useMediaXs();

  const groupedData = groupBy(cropStageSummary, (s) => s.cropStage.kind);

  const total = sumBy(cropStageSummary, "effectiveArea");
  const stagesData = Object.entries(groupedData).map(([kind, stages]) => {
    const totalEffectiveArea = sumBy(stages, "effectiveArea");
    const interpolator =
      kind == "production" ? prodInterpolator : devInterpolator;

    return {
      name: intl.formatMessage({ id: `cropStages.kinds.${kind}` }),
      kind,
      value: totalEffectiveArea,
      percentage: totalEffectiveArea / total,
      plants: sumBy(stages, "effectivePlants"),
      color: CROP_STAGE_COLOR_MAP[kind as CropStageType],
      children: stages.map((s, i) => ({
        name: s.cropStage.name,
        kind,
        value: roundUnit(s.effectiveArea),
        percentage: s.effectiveArea / totalEffectiveArea,
        plants: s.effectivePlants,
        color: interpolator(i / stages.length),
        cropFields: s.cropFields.map((cf, i) => ({
          name: cf.cropField.name,
          value: roundUnit(cf.effectiveArea),
          plants: cf.effectivePlants,
          percentage: cf.effectiveArea / s.effectiveArea,
          color: interpolator(i / s.cropFields.length),
        })),
      })),
    };
  });

  const [currentData, setCurrentData] = useState(stagesData);
  const flatData = currentData.map((d) => [d, d.children || []]).flat(2);

  return (
    <Sunburst
      data={{
        name: intl.formatMessage({ id: "cropStages" }),
        children: currentData,
      }}
      rawFields={["name", "value", "color", "percentage", "plants"]}
      radius={1}
      innerRadius={0.1}
      label={{
        labelEmit: true,
        offset: -40,
        layout: { type: "limit-in-shape" },
      }}
      colorField="color"
      color={({ color }) => color}
      meta={{
        name: {
          alias: intl.formatMessage({ id: "name" }),
        },
        value: {
          alias: intl.formatMessage({ id: "cropFields.effectiveArea" }),
          formatter: (v) => `${v} ${areaUnitAbbr}`,
        },
        percentage: {
          alias: intl.formatMessage({ id: "percent" }),
          formatter: (v) => `${roundUnit(v * 100)}%`,
        },
        plants: {
          alias: intl.formatMessage({ id: "cropFields.effectivePlants" }),
          formatter: (v) => intl.formatNumber(v),
        },
      }}
      tooltip={{
        title: (_, d) => d.name,
        fields: ["name", "percentage", "value", "plants"],
      }}
      hierarchyConfig={{ sort: () => 0 }}
      legend={{
        layout: "vertical",
        position: isXs ? "bottom" : "right-top",
        itemName: {
          formatter: (color) => {
            const d = flatData.find((d) => d.color == color);
            return d ? `${d.name} (${roundUnit(d.percentage * 100)}%)` : "";
          },
        },
      }}
      interactions={[Interactions.SingleSelected()]}
      onEvent={(_, e) => {
        const cropFields = e.data?.data?.data?.cropFields;
        if (e.type == "element:click") {
          if (cropFields) {
            setCurrentData(cropFields);
          }
        } else if (e.type == "plot:click" && !cropFields) {
          setCurrentData(stagesData);
        }
      }}
    />
  );
}

function CropVarietiesChart({
  cropVarietySummary,
  areaUnitAbbr,
}: Pick<DashboardReportFragment, "cropVarietySummary"> & {
  areaUnitAbbr: string;
}) {
  const intl = useIntl();
  const total = sumBy(cropVarietySummary, "effectiveArea");

  const varieties = cropVarietySummary.map((v) => {
    return {
      name: v.cropVariety.name,
      value: v.effectiveArea,
      percentage: v.effectiveArea / total,
      plants: v.effectivePlants,
      cropFields: v.cropFields.map((cf) => ({
        varietyName: v.cropVariety.name,
        name: cf.cropField.name,
        value: cf.effectiveArea,
        plants: cf.effectivePlants,
        percentage: cf.effectiveArea / v.effectiveArea,
        ages: cf.ages.map((a) => ({
          varietyName: v.cropVariety.name,
          name: a.age,
          value: a.effectiveArea,
          plants: a.effectivePlants,
          percentage: a.effectiveArea / v.effectiveArea,
        })),
      })),
    };
  });

  const [currentData, setCurrentData] = useState(varieties);
  const flatData = currentData.map((d) => [d, d.cropFields || []]).flat(2);
  const isXs = useMediaXs();

  const totalTitle = intl.formatMessage({ id: "total" });

  const [statisticTitle, setStatisticTitle] = useState(totalTitle);

  const chartValueFormatter = (v: number) => `${roundUnit(v)} ${areaUnitAbbr}`;

  const pieTotalFormater = usePieTotalFormater({
    chartValueFormatter,
    total,
    totalField: "value",
    showPercentage: statisticTitle != totalTitle,
  });

  return (
    <Pie
      data={currentData}
      angleField="value"
      colorField="name"
      radius={0.95}
      innerRadius={0.75}
      pieStyle={{ lineWidth: 3 }}
      meta={{
        name: {
          alias: intl.formatMessage({ id: "name" }),
        },
        value: {
          alias: intl.formatMessage({ id: "cropFields.effectiveArea" }),
          formatter: chartValueFormatter,
        },
        percentage: {
          alias: intl.formatMessage({ id: "percent" }),
          formatter: (v) => `${roundUnit(v * 100)}%`,
        },
        plants: {
          alias: intl.formatMessage({ id: "cropFields.effectivePlants" }),
          formatter: (v) => intl.formatNumber(v),
        },
        varietyName: {
          alias: intl.formatMessage({ id: "cropVarieties.entityName" }),
        },
      }}
      tooltip={{
        fields: ["name", "varietyName", "percentage", "value", "plants"],
      }}
      statistic={{
        title: {
          content: statisticTitle,
        },
        content: {
          customHtml: pieTotalFormater,
        },
      }}
      label={{
        type: "outer",
        content: "{percentage}",
      }}
      legend={{
        layout: "vertical",
        position: isXs ? "bottom" : "right-top",
        itemName: {
          formatter: (name) => {
            const d = flatData.find((d) => d.name == name);
            return d ? `${d.name} (${roundUnit(d.percentage * 100)}%)` : "";
          },
        },
      }}
      interactions={[Interactions.SingleSelected()]}
      onEvent={(_, e) => {
        if (e.type == "element:click") {
          const ages = e.data?.data?.ages;

          if (ages) {
            setCurrentData(ages);
            setStatisticTitle(e.data?.data?.name);
          } else {
            const cropFields = e.data?.data?.cropFields;
            if (cropFields) {
              setCurrentData(cropFields);
              setStatisticTitle(e.data?.data?.name);
            }
          }
        } else if (e.type == "plot:click" && !e.data) {
          setCurrentData(varieties);
          setStatisticTitle(totalTitle);
        }
      }}
    />
  );
}

export function LocalityOverview({
  loading,
  localitySummary,
  currentLocality,
  cropStageSummary = [],
  cropVarietySummary = [],
}: {
  loading: boolean;
  currentLocality?: LocalityShortFragment;
} & Partial<
  Pick<
    DashboardReportFragment,
    "cropStageSummary" | "cropVarietySummary" | "localitySummary"
  >
>) {
  const intl = useIntl();
  const { currentTenant } = useCurrentUser();

  if (!localitySummary || !currentLocality) return null;

  const { effectiveArea, __typename, ...localityOverview } = localitySummary;

  return (
    <Space vertical>
      {localityOverview && (
        <Row gutter={[8, 8]}>
          <Col flex="1 1 200px">
            <DashboardCard
              loading={loading}
              value={formatUnitValue(effectiveArea, {
                abbr: currentLocality.areaUnit.abbr,
              })}
              title={<FormattedMessage id="cropFields.effectiveArea" />}
            />
          </Col>

          {Object.entries(localityOverview).map(([key, value]) => {
            if (
              (key === "machineCount" && !currentTenant.features.machinery) ||
              (key === "plantCount" &&
                !currentTenant.features.customCropVarieties) ||
              (key === "workerCount" && !currentTenant.features.employees) ||
              (key === "infrastructureCount" &&
                !currentTenant.features.infrastructures)
            ) {
              return;
            }

            return (
              <Col flex="1 1 200px" key={`work-order-status-${key}`}>
                <DashboardCard
                  loading={loading}
                  value={formatNumber(value)}
                  title={intl.formatMessage(messages[key as MessageType])}
                />
              </Col>
            );
          })}
        </Row>
      )}

      <Row gutter={[8, 8]}>
        {currentTenant.features.customCropStages && (
          <DashboardCol
            loading={loading}
            link={routes.agro.cropStages.index}
            title={
              <FormattedMessage id="cropStages" defaultMessage="Crop Stages" />
            }
            hasData={!!cropStageSummary.length}
            noDataLink={routes.agro.cropStages.index}
            noDataLinkText={
              <FormattedMessage
                id="dashboard.cropStages"
                defaultMessage="Manage Crop Stages"
              />
            }
            noDataHint={
              <FormattedMessage
                id="dashboard.cropStages.hint"
                defaultMessage="Add crop stages to see them here"
              />
            }
          >
            <div>
              <CropStagesChart
                cropStageSummary={cropStageSummary}
                areaUnitAbbr={currentLocality.areaUnit.abbr}
              />
            </div>
          </DashboardCol>
        )}

        {currentTenant.features.customCropVarieties && (
          <DashboardCol
            loading={loading}
            title={<FormattedMessage id="cropVarieties" />}
            link={routes.agro.cropVarieties.index}
            hasData={!!cropVarietySummary.length}
            noDataLink={routes.agro.cropVarieties.index}
            noDataLinkText={
              <FormattedMessage
                id="dashboard.cropVarieties"
                defaultMessage="Create Crop Stages"
              />
            }
            noDataHint={
              <FormattedMessage
                id="dashboard.cropVarieties.hint"
                defaultMessage="Create crop varieties to see them here"
              />
            }
          >
            <div>
              <CropVarietiesChart
                cropVarietySummary={cropVarietySummary}
                areaUnitAbbr={currentLocality.areaUnit.abbr}
              />
            </div>
          </DashboardCol>
        )}
      </Row>
    </Space>
  );
}
