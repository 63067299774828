import findCenter from "@turf/center";
import bbox from "@turf/bbox";
import calculateArea from "@turf/area";

export type GeoFeatures = GeoJSON.FeatureCollection<
  | GeoJSON.Point
  | GeoJSON.MultiPoint
  | GeoJSON.LineString
  | GeoJSON.MultiLineString
  | GeoJSON.Polygon
  | GeoJSON.MultiPolygon
>;

export type MapCoordinate = [number, number]; // longitude, latitude

export function findGeoCenter(geoJson?: GeoFeatures | null) {
  if (!geoJson || geoJson.features.length === 0) return;

  return findCenter(geoJson);
}

export function findBoundaries(value?: GeoFeatures | null) {
  if (!value || value.features.length === 0) return;

  return bbox(value);
}

export function findCenterFromValue(value?: GeoFeatures | null) {
  const center = findGeoCenter(value);

  if (!center) return;

  return center.geometry.coordinates as MapCoordinate;
}

export function calculateGeoJsonArea(geoJson?: GeoFeatures) {
  if (!geoJson) return 0;

  return geoJson.features.reduce((sum: number, f: any) => {
    return sum + Math.round(calculateArea(f.geometry) * 100) / 100;
  }, 0);
}
