import dynamic from "next/dynamic";
import { useCurrentUser } from "../../lib/hooks";

import { defineMessages, useIntl } from "react-intl";
import { filterFalse } from "../../lib/utils";

const TVTicker = dynamic(
  () => import("react-ts-tradingview-widgets").then((w) => w.Ticker),
  {
    ssr: false,
  }
);

const titles = defineMessages({
  sp500: { id: "tickers.sp500", defaultMessage: "S&P 500 Index" },
  coffee: { id: "tickers.coffee", defaultMessage: "Coffee" },
  cocoa: { id: "tickers.cocoa", defaultMessage: "Cocoa" },
  cattle: { id: "tickers.cattle", defaultMessage: "Cattle" },
  sugar: { id: "tickers.sugar", defaultMessage: "Sugar" },
  oil: { id: "tickers.oil", defaultMessage: "Oil" },
});

export function Ticker() {
  const intl = useIntl();
  const { currentTenant } = useCurrentUser();

  return (
    <TVTicker
      colorTheme="light"
      copyrightStyles={{ parent: { display: "none" } }}
      symbols={filterFalse([
        {
          proName: "FOREXCOM:SPXUSD",
          title: intl.formatMessage(titles.sp500),
        },
        currentTenant.currencyCode !== "USD" && {
          proName: `FX_IDC:USD${currentTenant.currencyCode}`,
          title: currentTenant.currencyCode,
        },
        {
          proName: "BLACKBULL:WTI",
          title: intl.formatMessage(titles.oil),
        },
        {
          proName: "CME:LE1!",
          title: intl.formatMessage(titles.cattle),
        },
        {
          proName: "PEPPERSTONE:COFFEE",
          title: intl.formatMessage(titles.coffee),
        },
        {
          proName: "PEPPERSTONE:COCOA",
          title: intl.formatMessage(titles.cocoa),
        },
        {
          proName: "PEPPERSTONE:SUGAR",
          title: intl.formatMessage(titles.sugar),
        },
      ])}
    />
  );
}
