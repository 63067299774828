import { ColProps, Col, Card, Typography, Button } from "antd";
import Link from "next/link";
import { PropsWithChildren, ReactNode } from "react";
import { Space } from "../shared";

export type DashboardColProps = PropsWithChildren<{
  loading: boolean;
  hasData: boolean;
  link: string;
  title: ReactNode;
  noDataHint?: ReactNode;
  noDataLink?: string;
  noDataLinkText?: ReactNode;
  colProps?: ColProps;
}>;

export default function DashboardCol({
  children,
  loading,
  hasData,
  link,
  title,
  noDataHint,
  noDataLink,
  noDataLinkText,
  colProps,
}: DashboardColProps) {
  return (
    <Col xs={24} md={12} {...colProps}>
      <Card
        loading={loading}
        style={{ height: "100%" }}
        bodyStyle={{ height: "100%" }}
      >
        <Typography.Title
          level={4}
          style={hasData ? undefined : { marginBottom: 0 }}
        >
          <Link
            href={link}
            as={link}
            prefetch={false}
            target="_blank"
            className="text-link details-link details-link-blank"
          >
            {title}
          </Link>
        </Typography.Title>

        {!hasData ? (
          <Space
            vertical
            align="center"
            justify="center"
            style={{
              height: "100%",
              paddingBottom: "0.5rem",
            }}
          >
            {noDataHint && (
              <div style={{ textAlign: "center" }}>
                <Typography.Text>{noDataHint}</Typography.Text>
              </div>
            )}

            {noDataLink && (
              <Button type="primary" size="large" href={noDataLink}>
                {noDataLinkText}
              </Button>
            )}
          </Space>
        ) : (
          children
        )}
      </Card>
    </Col>
  );
}
