import { Table } from "antd";
import { FormattedMessage } from "react-intl";
import {
  formatActivity,
  formatDate,
  formatDetailsLink,
  formatStatus,
} from "../../lib/formats";
import { useCurrentUser, useWorkOrders } from "../../lib/hooks";
import routes from "../../lib/routes";
import { WorkOrderStatus } from "../../lib/graphql";
export function RecentWorkOrdersTable() {
  const { currentLocalityIds } = useCurrentUser();

  const { loading, items } = useWorkOrders({
    variables: {
      filter: {
        localityId: currentLocalityIds,
        status: [WorkOrderStatus.Completed],
      },
      pageSize: 5,
    },
  });

  return (
    <Table
      dataSource={items}
      pagination={false}
      rowKey="id"
      loading={loading}
      rowClassName="cursor-pointer"
      scroll={{ x: true }}
      columns={[
        {
          title: <FormattedMessage id="id" />,
          dataIndex: "id",
          render: (id) =>
            formatDetailsLink({
              id,
              route: routes.agro.workOrders.details,
              target: "",
            }),
        },
        {
          title: <FormattedMessage id="activities.entityName" />,
          dataIndex: ["activity", "name"],
          render: (name, wo) => formatActivity(name, wo.cycleNumber),
        },
        {
          title: <FormattedMessage id="date" />,
          dataIndex: "documentDate",
          render: (v) => formatDate(v),
        },
        {
          title: <FormattedMessage id="status" />,
          dataIndex: "status",
          render: (s) => formatStatus(s, "tag"),
        },
      ]}
    />
  );
}
