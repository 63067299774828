import { Button, Card, Col, Row, Space, Typography } from "antd";
import { FormattedMessage } from "react-intl";
import { Logo } from "../shared";

export default function Welcome({ onNextClick }: { onNextClick: () => void }) {
  return (
    <Row
      justify="center"
      align="middle"
      style={{ minHeight: "calc(100vh - 50px)" }}
    >
      <Col
        style={{
          textAlign: "center",
          maxWidth: "600px",
        }}
      >
        <Card>
          <Space direction="vertical" size="middle">
            <Typography.Title level={2} style={{ margin: 0 }}>
              <FormattedMessage
                id="onboarding"
                defaultMessage="Welcome to Aragro"
              />
            </Typography.Title>

            <Logo size="medium" />

            <Typography.Title level={4} style={{ margin: 0 }}>
              <FormattedMessage
                id="onboarding.welcome.subtitle"
                defaultMessage="Get ready to start"
              />
            </Typography.Title>

            <Typography.Text>
              <FormattedMessage
                id="onboarding.welcome.content"
                defaultMessage="content"
              />
            </Typography.Text>

            <Button type="primary" onClick={onNextClick} size="large">
              <FormattedMessage
                id="onboarding.start"
                defaultMessage="Let's get started"
              />
            </Button>
          </Space>
        </Card>
      </Col>
    </Row>
  );
}
