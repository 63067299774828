import { defineMessages, useIntl } from "react-intl";
import ProductTour, { IStep } from "../shared/ProductTour";
import { useCurrentUser } from "../../lib/hooks";

const onboardingMessages = defineMessages({
  leftMenuTitle: {
    id: "onboarding.leftMenu.title",
    defaultMessage: "Main menu",
  },
  leftMenuContent: {
    id: "onboarding.leftMenu.content",
    defaultMessage: "Access to functions",
  },
  leftMenuHomeTitle: {
    id: "onboarding.leftMenuHome.title",
    defaultMessage: "Open dashboard",
  },
  leftMenuHomeContent: {
    id: "onboarding.leftMenuHome.content",
    defaultMessage: "Navigate to dashboard",
  },
  closeLeftMenuTitle: {
    id: "onboarding.closeLeftMenu.title",
    defaultMessage: "Control main menu size",
  },
  closeLeftMenuContent: {
    id: "onboarding.closeLeftMenu.content",
    defaultMessage: "Shrink or expand size of main menu",
  },
  localitySelectorTitle: {
    id: "onboarding.localitySelector.title",
    defaultMessage: "Farms",
  },
  localitySelectorContent: {
    id: "onboarding.localitySelector.content",
    defaultMessage: "Choose farm",
  },
  cropCycleSelectorTitle: {
    id: "onboarding.cropCycleSelector.title",
    defaultMessage: "Crop cycles",
  },
  cropCycleSelectorContent: {
    id: "onboarding.cropCycleSelector.content",
    defaultMessage: "Choose crop cycle",
  },
  modulesMenuTitle: {
    id: "onboarding.modulesMenu.title",
    defaultMessage: "Administration menu",
  },
  modulesMenuContent: {
    id: "onboarding.modulesMenu.content",
    defaultMessage: "Access to all modules",
  },
});

const KEY = "onboarding:site";

export default function SiteProductTour() {
  const { currentTenant } = useCurrentUser();

  const intl = useIntl();

  const steps = [] as IStep[];

  steps.push(
    {
      selector: ".ant-layout-sider",
      placement: "right",
      title: intl.formatMessage(onboardingMessages.leftMenuTitle),
      content: intl.formatMessage(onboardingMessages.leftMenuContent),
      fixedBody: true,
    },
    {
      selector: "[data-action='home-link']",
      placement: "right-top",
      title: intl.formatMessage(onboardingMessages.leftMenuHomeTitle),
      content: intl.formatMessage(onboardingMessages.leftMenuHomeContent),
    },
    {
      selector: "[data-action='left-menu-toggle']",
      placement: "bottom-left",
      title: intl.formatMessage(onboardingMessages.closeLeftMenuTitle),
      content: intl.formatMessage(onboardingMessages.closeLeftMenuContent),
      parent: "body",
    },
    {
      selector: "[data-action='crop-cycle-selector']",
      placement: "bottom",
      title: intl.formatMessage(onboardingMessages.cropCycleSelectorTitle),
      content: intl.formatMessage(onboardingMessages.cropCycleSelectorContent),
    },
    {
      selector: "[data-action='modules-menu']",
      placement: "bottom",
      title: intl.formatMessage(onboardingMessages.modulesMenuTitle),
      content: intl.formatMessage(onboardingMessages.modulesMenuContent),
    }
  );

  if (currentTenant.features.multipleFarms) {
    steps.splice(3, 0, {
      selector: "[data-action='locality-selector']",
      placement: "bottom",
      title: intl.formatMessage(onboardingMessages.localitySelectorTitle),
      content: intl.formatMessage(onboardingMessages.localitySelectorContent),
    });
  }

  return <ProductTour hotspot={false} localKey={KEY} steps={steps} />;
}
