import { Table } from "antd";
import { FormattedMessage } from "react-intl";
import {
  formatDate,
  formatDetailsLink,
  formatMoneyAmount,
  formatStatus,
  formatWarehouse,
} from "../../lib/formats";
import {
  CurrentTenantLocalityFragment,
  InventoryDocumentFilterType,
  InventoryOrderFragment,
  InventoryOrderKind,
  InventoryOrderStatus,
  SortDirection,
} from "../../lib/graphql";
import { useInventoryOrders, usePermissions } from "../../lib/hooks";
import routes from "../../lib/routes";
import DashboardCol from "./DashboardCol";
import { filterColumns } from "../../lib/utils";

export function RecentPurchases({
  currentLocality,
  currencyCode,
}: {
  currentLocality?: CurrentTenantLocalityFragment;
  currencyCode: string;
}) {
  const showCost = usePermissions((p) => !!p.settings?.showCost);
  const { loading, items } = useInventoryOrders({
    variables: {
      filter: {
        localityId: currentLocality ? [currentLocality.id] : undefined,
        kind: [InventoryOrderKind.Purchase],
        status: [InventoryOrderStatus.Completed],
        showCurrencyCode: currencyCode,
      },
      sort: {
        documentDate: SortDirection.Desc,
      },
      pageSize: 5,
    },
    fetchPolicy: "cache-and-network", // it returns stale data otherwise on currency change
  });

  return (
    <DashboardCol
      loading={loading}
      link={routes.reports.inventoryMovements({
        documentType: InventoryDocumentFilterType.Purchase,
        localityId: currentLocality ? [currentLocality.id] : undefined,
      })}
      title={
        <FormattedMessage
          id="dashboard.recentPurchases"
          defaultMessage="recentPurchases"
        />
      }
      hasData={!!items?.length}
      noDataLink={routes.inventory.purchases.new}
      noDataHint={
        <FormattedMessage
          id="dashboard.purchaseInventory.hint"
          defaultMessage="It looks like you didn't purchase any inventory yet."
        />
      }
      noDataLinkText={
        <FormattedMessage
          id="dashboard.purchaseInventory"
          defaultMessage="Purchase Inventory"
        />
      }
    >
      <Table
        dataSource={items}
        pagination={false}
        scroll={{ x: true }}
        rowKey="id"
        loading={loading}
        rowClassName="cursor-pointer"
        columns={filterColumns<InventoryOrderFragment>([
          {
            title: <FormattedMessage id="id" />,
            dataIndex: "id",
            render: (id) =>
              formatDetailsLink({
                id,
                route: routes.inventory.purchases.details,
                target: "",
              }),
          },
          {
            title: <FormattedMessage id="inventorySaleOrders.counterparty" />,
            render: (_, i) => i.counterparty.name,
          },
          {
            title: <FormattedMessage id="warehouses.entityName" />,
            render: (_, i) => formatWarehouse(i.warehouse),
          },
          {
            title: <FormattedMessage id="date" />,
            render: (_, i) => formatDate(i.documentDate),
          },
          showCost && {
            title: <FormattedMessage id="total" />,
            render: (_, i) => formatMoneyAmount(i.total, i.currencyCode),
          },
          {
            title: <FormattedMessage id="status" />,
            dataIndex: "status",
            align: "center",
            render: (_, i) => formatStatus(i.status, "tag"),
          },
        ])}
      />
    </DashboardCol>
  );
}
