import { Table } from "antd";
import { FormattedMessage, useIntl } from "react-intl";
import {
  formatDate,
  formatDetailsLink,
  formatInvoiceStatus,
  formatMoneyAmount,
} from "../../lib/formats";
import {
  FinanceInvoiceFragment,
  InvoiceStatus,
  SortDirection,
} from "../../lib/graphql";
import {
  useCurrentUser,
  useFinanceInvoices,
  usePermissions,
} from "../../lib/hooks";
import routes from "../../lib/routes";
import DashboardCol, { DashboardColProps } from "./DashboardCol";
import { filterColumns } from "../../lib/utils";

export function UpcomingInvoicesTable({
  currencyCode,
  colProps,
}: {
  currencyCode: string;
  colProps: DashboardColProps["colProps"];
}) {
  const intl = useIntl();
  const { currentLocalityId } = useCurrentUser();
  const showCost = usePermissions((p) => !!p.settings?.showCost);
  const { loading, items } = useFinanceInvoices({
    variables: {
      filter: {
        localityId: currentLocalityId ? [currentLocalityId] : undefined,
        invoiceStatus: [InvoiceStatus.Due, InvoiceStatus.DueSoon],
        currencyCode,
      },
      sort: {
        dueDate: SortDirection.Asc,
      },
      pageSize: 5,
    },
  });

  return (
    <DashboardCol
      hasData={!!items?.length}
      colProps={colProps}
      loading={loading}
      link={routes.finance.invoices.index}
      title={
        <FormattedMessage
          id="dashboard.upcomingInvoices"
          defaultMessage="upcomingInvoices"
        />
      }
      noDataHint={
        <FormattedMessage
          id="dashboard.upcomingInvoices.noData"
          defaultMessage="No upcoming invoices"
        />
      }
    >
      <Table
        dataSource={items}
        pagination={false}
        scroll={{ x: true }}
        rowKey="id"
        loading={loading}
        rowClassName="cursor-pointer"
        columns={filterColumns<FinanceInvoiceFragment>([
          {
            title: <FormattedMessage id="id" />,
            dataIndex: "id",
            render: (id) =>
              formatDetailsLink({
                id,
                title: intl.formatMessage({ id: "pay", defaultMessage: "Pay" }),
                route: routes.finance.invoices.details,
                target: "",
              }),
          },
          {
            title: <FormattedMessage id="invoices.counterparty" />,
            render: (_, i) => i.counterparty.name,
          },
          {
            title: <FormattedMessage id="dueDate" />,
            dataIndex: "dueDate",
            render: (_, i) => formatDate(i.dueDate),
          },
          showCost && {
            title: <FormattedMessage id="invoices.amountToPay" />,
            render: (_, i) => formatMoneyAmount(i.amountToPay, i.currencyCode),
          },
          {
            title: <FormattedMessage id="status" />,
            dataIndex: "invoiceStatus",
            align: "center",
            render: (_, i) => formatInvoiceStatus(i),
          },
        ])}
      />
    </DashboardCol>
  );
}
