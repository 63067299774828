import Icon from "@ant-design/icons";
import { Col, Card, Typography, Row } from "antd";

interface ExpensesCardProps {
  loading: boolean;
  value: JSX.Element | null;
  icon?: string;
  title: string | JSX.Element;
  hoverable?: boolean;
}

export default function DashboardCard({
  loading,
  value,
  icon,
  title,
  hoverable,
}: ExpensesCardProps) {
  return (
    <Card loading={loading} hoverable={hoverable} style={{ height: "100%" }}>
      <Typography.Paragraph type="secondary" strong>
        {title}
      </Typography.Paragraph>

      <Row gutter={16} align="middle">
        {icon && (
          <Col>
            <Icon component={() => <img src={`/images/icons/${icon}.svg`} />} />
          </Col>
        )}

        <Col>
          <Typography.Title level={3} style={{ marginBottom: 0 }}>
            {value}
          </Typography.Title>
        </Col>
      </Row>
    </Card>
  );
}
