import { Pie } from "@ant-design/plots";
import type { Data, Datum } from "@antv/g2plot";
import type { View } from "@antv/g2/lib";
import { Typography } from "antd";
import { sumBy } from "lodash";
import { useState, useEffect, useMemo } from "react";
import { defineMessages, useIntl } from "react-intl";
import { Interactions, roundUnit } from "../../lib/formats";
import { DashboardActivityExpense } from "../../lib/graphql";
import { useMediaXs, usePermissions } from "../../lib/hooks";

const messages = defineMessages({
  other: { id: "other", defaultMessage: "Other" },
});

export function useChartValueFormatter(currency: string) {
  const intl = useIntl();
  const showCost = usePermissions((p) => !!p.settings?.showCost);

  return (v: number) => {
    return showCost
      ? intl.formatNumber(v, {
          style: "currency",
          currency,
          currencyDisplay: "narrowSymbol",
        })
      : " ";
  };
}

export function usePieTotalFormater<T extends Data>({
  total,
  totalField = "total",
  showPercentage,
  chartValueFormatter,
}: {
  total: number;
  totalField?: string;
  showPercentage: boolean;
  chartValueFormatter?: (v: number) => string;
}) {
  return (_c: HTMLElement, _v: View, _d?: Datum, data?: T) => {
    const currentTotal = data?.reduce((r, d) => r + d[totalField], 0) || 0;

    return (
      <>
        <div style={{ marginTop: "8px", marginBottom: "8px" }}>
          {chartValueFormatter
            ? chartValueFormatter(currentTotal)
            : currentTotal}
        </div>

        {showPercentage && (
          <Typography.Paragraph type="secondary" style={{ fontSize: "1.3rem" }}>
            {roundUnit((currentTotal / total) * 100)}%
          </Typography.Paragraph>
        )}
      </>
    ) as unknown as string;
  };
}

export default function ActivitiesPie({
  currency,
  activityExpenses,
}: {
  activityExpenses?: Array<DashboardActivityExpense> | null;
  currency: string;
}) {
  const intl = useIntl();
  const showCost = usePermissions((p) => !!p.settings?.showCost);

  const isXs = useMediaXs();
  const expencesChartData = useMemo(
    () =>
      [...(activityExpenses || [])]
        .sort((a, b) => b.expenses - a.expenses)
        .reduce((result, category, index) => {
          if (index < 5) {
            result.push(category);
            return result;
          }

          let other = null;
          if (index == 5) {
            other = {
              name: intl.formatMessage(messages.other),
              expenses: 0,
              categories: [],
            };
            result.push(other);
          } else {
            other = result.slice(-1)[0];
          }
          other.expenses += category.expenses;
          other.categories?.push(category);

          return result;
        }, [] as Array<DashboardActivityExpense & { categories?: DashboardActivityExpense[] }>),
    [activityExpenses, intl]
  );
  const [currentData, setCurrentData] = useState(expencesChartData);

  const totalTitle = intl.formatMessage({ id: "total" });

  const [statisticTitle, setStatisticTitle] = useState(totalTitle);
  const total = sumBy(activityExpenses, (e) => e.expenses);
  const currentTotal = sumBy(currentData, (e) => e.expenses);

  useEffect(() => setCurrentData(expencesChartData), [expencesChartData]);

  const chartValueFormatter = useChartValueFormatter(currency);
  const pieTotalFormater = usePieTotalFormater({
    chartValueFormatter,
    total,
    totalField: "expenses",
    showPercentage: showCost ? statisticTitle != totalTitle : true,
  });

  return (
    <Pie
      angleField="expenses"
      colorField="name"
      data={currentData}
      radius={0.95}
      innerRadius={0.75}
      pieStyle={{ lineWidth: 3 }}
      meta={{
        expenses: {
          formatter: chartValueFormatter,
        },
      }}
      label={{
        type: "outer",
        content: "{percentage}",
      }}
      statistic={{
        title: {
          content: statisticTitle,
        },
        content: {
          customHtml: pieTotalFormater,
        },
      }}
      interactions={[Interactions.SingleSelected()]}
      legend={{
        layout: "vertical",
        position: isXs ? "bottom" : "right-top",
        itemName: {
          formatter(text, _item, index) {
            const item = currentData[index];
            return `${text} (${roundUnit(
              (item.expenses / currentTotal) * 100
            )}%)`;
          },
        },
      }}
      onEvent={(_, e) => {
        if (e.type == "element:click") {
          const activities = e.data?.data?.activities;
          if (activities) {
            setStatisticTitle(e.data?.data?.name);
            setCurrentData(activities);
          }
          const categories = e.data?.data?.categories;
          if (categories) {
            setStatisticTitle(e.data?.data?.name);
            setCurrentData(categories);
          }
        } else if (e.type == "plot:click" && !e.data) {
          setStatisticTitle(totalTitle);
          setCurrentData(expencesChartData);
        }
      }}
    />
  );
}
