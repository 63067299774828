import { QueryHookOptions, useQuery } from "@apollo/client";
import {
  DashboardReportQuery,
  DashboardReportQueryVariables,
  DashboardReportDocument,
} from "../../graphql";

export function useDashboardReport(
  options?: QueryHookOptions<
    DashboardReportQuery,
    DashboardReportQueryVariables
  >
) {
  const { loading, data } = useQuery(DashboardReportDocument, options);

  if (!data || !data.dashboardReport) {
    return { loading };
  }

  const report = data.dashboardReport || {};

  return {
    loading,
    expenses: {
      totalManDays: report.totalManDays,
      totalExpenses: report.totalExpenses,
      totalMachineHours: report.totalMachineHours,
      activityExpenses: report.activityExpenses,
      allExpenses: report.expenses,
    },
    workOrders: {
      workOrderStatusSummary: report.workOrderStatusSummary,
      totalAwaitingInventoryWorkOrders: report.totalAwaitingInventoryWorkOrders,
      workOrderTypeSummary: report.workOrderTypeSummary,
    },
    localitySummary: {
      localitySummary: report.localitySummary,
      cropStageSummary: report.cropStageSummary,
      cropVarietySummary: report.cropVarietySummary,
    },
  };
}
