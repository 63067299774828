import { Spin, Typography } from "antd";
import { PropsWithChildren } from "react";
import { defineMessages, useIntl } from "react-intl";
import { LocalityForm } from "../../components/agro";
import { Form } from "../../components/form";
import { BottomPanel } from "../../components/shared";
import BasicLayout from "../../layouts/BasicLayout";
import { LocalityFields, LocalityFragment, UnitType } from "../../lib/graphql";
import { evictCache, useLocalityCreate, useUnitOptions } from "../../lib/hooks";
import routes, { routerPush } from "../../lib/routes";

const messages = defineMessages({
  edit: { id: "edit.header", defaultMessage: "edit" },
  locality: {
    id: "localities.entityName",
    defaultMessage: "locality",
  },
  hint: { id: "localities.editHint", defaultMessage: "editHint" },
});

export default function New() {
  const intl = useIntl();
  const entityName = intl.formatMessage(messages.locality);

  return (
    <BasicLayout
      title={intl.formatMessage(messages.edit, { entityName })}
      breadcrumbs={[
        { path: routes.localities.index, breadcrumbName: entityName },
        {
          path: routes.localities.new,
          breadcrumbName: intl.formatMessage({ id: "new" }),
        },
      ]}
      headerContent={
        <Typography.Text type="secondary">
          {intl.formatMessage(messages.hint)}
        </Typography.Text>
      }
      authorize={(p) => !!p?.locality?.write}
    >
      <NewLocalityForm
        afterSubmit={(locality: LocalityFragment) => {
          routerPush(locality.id, routes.localities.details);
        }}
      >
        <BottomPanel
          buttons={[
            BottomPanel.CancelButton({
              route: routes.localities.index,
            }),
            BottomPanel.SubmitButton(),
          ]}
        />
      </NewLocalityForm>
    </BasicLayout>
  );
}

export function NewLocalityForm({
  children,
  afterSubmit,
}: PropsWithChildren<{
  afterSubmit?: (locality: LocalityFragment) => void;
}>) {
  const [create] = useLocalityCreate({
    update: evictCache("onboardingStatus", "localities"),
    refetchQueries: ["Me"],
  });

  const { unitOptions } = useUnitOptions({
    types: [UnitType.Area],
  });

  if (!unitOptions.length)
    return (
      <div style={{ textAlign: "center" }}>
        <Spin />
      </div>
    );

  return (
    <Form
      preventLeaving
      layout="vertical"
      initialValues={{ areaUnitId: unitOptions[0].key } as LocalityFields}
      onSubmit={(locality, { setSubmitting, showErrors }) => {
        create({
          variables: {
            fields: {
              name: locality.name,
              internalId: locality.internalId,
              geoJson: locality.geoJson,
              areaUnitId: locality.areaUnitId,
              totalArea: locality.totalArea,
            },
          },
        }).then((f) => {
          if (!f.data?.localityCreate) return;

          if (f.data?.localityCreate?.result) {
            if (afterSubmit) afterSubmit(f.data.localityCreate.result);
          } else if (f.errors) {
            showErrors(f.data.localityCreate.errors);
            setSubmitting(false);
          }
        });
      }}
    >
      <LocalityForm unitOptions={unitOptions} />

      {children}
    </Form>
  );
}
